.backBtnWrapper {
  display: inline-block;
}

.backBtn {
  background: transparent;
  border: none;
  float: right;
  text-align: right;
  margin-bottom: 0.5%;
}
